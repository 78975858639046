import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Terms and Conditions" />
    <div className="container  mx-auto py-6 px-6">
      <h1 className="text-center text-4xl text-black font-medium leading-snug tracking-wider">
        Privacy Policy
      </h1>
      {/* <p className="italic">Last updated: March 09, 2019</p> */}
      <br></br>
      <div className="leading-loose space-y-6 ">
        Cargo-Planner is a product developed and maintained by Törnblom Software
        AB, a Swedish registered company that processes Personal Data (as
        defined below) of people using our Application app.cargoplanner.se,
        app.cargo-planner.com and app.cargo-planner.net, refferd to below as the
        "Site". This “Privacy Policy” lets You (as defined below) know how
        Törnblom Software AB collects and uses Your Personal Data and how You
        can control its use
        <h2 className="text-2xl">1. SCOPE OF THIS PRIVACY POLICY</h2>
        <p>
          This Privacy Policy sets forth the principles and guidelines governing
          the protection of the Personal Data (as defined below) collected on or
          through the Site and concerning the Site’s visitors and users ("You"
          or "Your").
          <br />
          Personal data ("Personal Data") means any information relating to an
          identified or identifiable person. An identifiable person is a person
          who can be identified, directly or indirectly, in particular by
          reference to an identification number or to one or more factors
          specific to the person. Non-Personal Data means any information that
          does not result in the identification of a person.
        </p>
        <h2 className="text-2xl">2. DATA COLLECTED</h2>
        <p>
          In order to use certain functions and features of the Site, Törnblom
          Software AB requires that You provide certain Personal Data when using
          the Site. Personal Data includes
          <br /> <br />
          email address <br />
          username and password <br />
          Company (which in most case will be the same as your employer) <br />
          IP address, <br />
          <br />
          and any other Data as may be relevant for the purposes listed below
          Each registered Company may contain, but is not required, to contain
          <br />
          <br />
          Company Name <br />
          Department <br />
          Address <br />
          Postal Code <br />
          Country <br />
          VAT Number <br /> <br />
          This information could be considered as Personal Data in cases (such
          as but not limited to) where the registered user is connected to a
          Sole Proprietor company <br />
          In all cases, however, the Personal Data gathered is limited to data
          necessary to the purposes described under Article 3. <br /> <br />
          You are free to decide whether or not to provide all or part of Your
          Personal Data. However, if You choose not to provide all or part of
          this Personal Data, the purposes as described in Article 3 hereafter
          might not be achieved or not properly achieved, some areas and
          functions of the Site may not function properly and/or You may be
          refused access to certain pages on the Site. In particular, You will
          not be authorized to access certain services via the Site. <br />
          <br />
          The Site also uses the Tawk.to online chat service, in order for
          Törnblom Software AB to provide You with real time support. All data
          being transmitted through the live chat, will be stored and processed
          by Tawk.to. Therefore personally identifiable data entered by a user
          will be transmitted to Tawk.to. Aside from data entered by the user,
          Tawk.to will automatically store the following data: <br />
          <br />
          Language (detected based on browser language) <br />
          IP-address <br />
          <br />
          All personally identifying data are being processed by Tawk.to and are
          only used to solve customer support cases and support the customer in
          the Live-Chat environment. The privacy policy of Tawk.to can be found
          at https://www.tawk.to/privacy-policy/.
        </p>
        <h2 className="text-2xl">
          3. METHODS AND PURPOSES OF COLLECTION OF PERSONAL DATA
        </h2>
        <p>
          As stated above, You provide Your Personal Data on a voluntary basis.
          Törnblom Software AB will collect Personal Data either online or
          offline and this Privacy Policy is applicable regardless of the means
          of collection. In the case of online collection, this Privacy Policy
          is posted on the Site and in the case of offline collection, You will
          be notified by this Privacy Policy. Personal Data is generally
          collected to carry out the business of Törnblom Software AB, such as
          improving Törnblom Software AB marketing and advertising efforts,
          better tailoring Törnblom Software AB products and services to
          customers’ needs or complying with reporting obligations required by
          law, and similar activities. <br />
          <br />
          Your Personal Data collected by Törnblom Software AB on or through the
          Site is used by Törnblom Software AB for the following purposes:
          <br />
          <br />
          To enable You to request information <br />
          To provide You with a personalised interactive use of the Site <br />
          To allow You to open and maintain an account in order to obtain
          specialised documentation and technical assistance <br />
          To access all the features and options offered by the Site; and Any
          other purposes associated with the above purposes <br />
          <br />
          By providing Your e-mail address, You hereby expressly authorize,
          Törnblom Software AB and its subsidiaries to use it together with
          other relevant Personal Data to send You commercial or marketing
          messages. <br />
          Törnblom Software AB might also use Your email address for
          administrative or other non-marketing purposes (for example, to notify
          You significant changes to the Site, or to start a dialogue with you
          in case your current work is suspected to have been affected by
          malfunction of the Site).
        </p>
        <h2 className="text-2xl">4. COOKIES AND LOCAL STORAGE</h2>
        <p>
          Cookies and Local Storage are text files and data stored and used to
          record non-personal and personal information concerning Your browsing
          of the Site. <br />
          <br />
          Cargo-Planner may use cookies or other technologies which may collect
          or store Personal Data to improve services for You through, for
          example: <br />
          <br />
          enabling a service to recognize your device so You don't have to give
          the same information several times during one task <br />
          recognizing that You may already have given a username and password so
          you don't need to do it for every web page requested <br />
          analysing data to help Törnblom Software AB understand how people
          interact with services so Törnblom Software AB can make them better
          <br />
          <br />
          Törnblom Software AB uses two types of cookies: permanent cookies and
          temporary cookies. Temporary cookies disappear as soon as You log off
          from the Site. Permanent cookies remain after logging off from the
          Site in order to be used on subsequent visits to the Site. By
          continuing to use the Site in consideration of the above, You
          expressly authorize Törnblom Software AB to use such cookies. <br />
          <br />
          You can also configure Your browser software to refuse all cookies; if
          You do so, however, some areas and features of the Site may not
          function properly and/or You may not access some parts or services of
          the Site.
        </p>
        <h2 className="text-2xl">
          5. PERSONAL DATA PROCESSING AND STORAGE TERM
        </h2>
        <p>
          Processing of Personal Data includes using, storing, recording,
          transferring, adapting, summarizing, amending, reporting, sharing and
          destroying Personal Data as necessary under the circumstances as
          permitted by law or as otherwise required by law. <br />
          <br />
          All Personal Data that will have been collected will be stored for a
          limited duration that is relevant to the purpose of such collection
          and for so long as required by applicable law.
        </p>
        <h2 className="text-2xl">6. TRANSFER OF PERSONAL DATA</h2>
        <p>
          If You are accessing the Site from a location outside of the European
          Union, with laws or regulations governing data collection, use, and
          disclosure that differ from European Union, please note that through
          Your continued use of the Site, which are governed by Swedish law, the
          corresponding Terms of Use and this Privacy Policy, You are
          transferring Your Personal Data to the European Union and You consent
          to that transfer. <br />
          <br />
          Where Törnblom Software AB has knowledge that a third party to which
          Törnblom Software AB has provided Personal Data for the purposes
          listed in Article 3 above, is using or disclosing Personal Data
          contrary to this Privacy Policy or to applicable laws, Törnblom
          Software AB will take reasonable steps to prevent or stop such use or
          disclosure. <br />
          <br />
          You will have the opportunity to decide whether You authorize Törnblom
          Software AB to use Your Personal Data for a purpose differing from the
          purpose for which it was originally collected. <br />
          <br />
          Törnblom Software AB will also disclose Personal Data to third parties
          if Törnblom Software AB determines that such disclosure is necessary
          for technical reasons (such as hosting services by a third party) or
          to protect Törnblom Software AB’s legal interests (such as in the
          event of acquisition or merger by a third company or total or partial
          liquidation of Törnblom Software AB).). These transfers may be
          transmitted over the internet, via mail, via facsimile, or by any
          other method which Törnblom Software AB determines is appropriate and
          in accordance with applicable law.
        </p>
        <h2 className="text-2xl">
          7. RIGHT OF ACCESS TO AND RECTIFICATION OF THE DATA
        </h2>
        <p>
          Törnblom Software AB will take reasonable steps to ensure that the
          Personal Data collected is used for the purposes stated in this
          Privacy Policy and that such Personal Data is correct and up to date.
          <br />
          <br />
          You have a right to access Your Personal Data. In addition, You have a
          right to request the rectification, completion, update or erasure of
          Your Personal Data. You also have a right to obtain a copy of the
          Personal Data conserved by Törnblom Software AB. If You have an
          account, You can exercise Your rights of access to and rectification
          of the Personal Data by logging on to Your account and visiting the
          “Profile” page. Otherwise, You may exercise Your rights of access to
          and rectification of the data by sending an email to the following
          address: info@cargoplanner.se. <br />
          <br />
          Depending on the scope of the request, Törnblom Software AB reserves
          the right to charge a reasonable fee to cover any out-of-pocket costs
          incurred in connection with such access, modification and deletion of
          Personal Data. Törnblom Software AB may deny access to Personal Data
          in limited circumstances, defined by applicable laws and regulations.
        </p>
        <h2 className="text-2xl">8. DATA SECURITY AND RECIPIENTS</h2>
        <p>
          Törnblom Software AB undertakes to ensure the protection and security
          of Personal Data that You choose to communicate, in order to ensure
          the confidentiality of Your Personal Data and prevent Your Personal
          Data from being distorted, damaged, destroyed or disclosed to
          unauthorized parties. <br />
          <br />
          Törnblom Software AB maintains reasonable physical, electronic, and
          procedural safeguards to protect Personal Data from loss, misuse and
          unauthorized access, disclosure, alternation and destruction. As part
          of those safeguards, Törnblom Software AB employs sophisticated
          technology designed to protect Personal Data during its transmission
          and prevent transmission errors or unauthorized acts of third parties.
          However, while Törnblom Software AB strives to protect Your Personal
          Data, in light of the inevitable risks of data transmission over the
          internet, Törnblom Software AB cannot guarantee full protection
          against any error occurring during the course of Personal Data
          transmission which is beyond Törnblom Software AB’s reasonable
          control. <br />
          <br />
          Since all Personal Data is confidential, access is limited to
          employees, contractors and agents of Törnblom Software AB who have a
          need to know such data in carrying out their tasks. All the people who
          have access to Your Personal Data are bound by a duty of
          confidentiality and subject to disciplinary actions and/or other
          sanctions if they fail to meet these obligations. <br />
          <br />
          However, it is important for You to exercise caution to prevent
          unauthorized access to Your Personal Data. You are responsible for the
          confidentiality of Your password and information appearing on Your
          account. Consequently, You must ensure that You log out of Your
          session in the event of shared use of a computer.
        </p>
        <h2 className="text-2xl">9. DISPUTE RESOLUTION</h2>
        <p>
          Although Törnblom Software AB has put in place reasonable safeguards
          to protect Personal Data, we recognize that there is no method of
          transmitting or storing Personal Data that is completely secure.
          However, Törnblom Software AB is committed to ensure the privacy of
          Personal Data: if You have a reason to believe that the security of
          Your Personal Data has been compromised or misused, You should contact
          Törnblom Software AB via the following address: info@cargoplanner.se.
          Törnblom Software AB will investigate and attempt to resolve
          complaints regarding use and disclosure of Personal Data in accordance
          with the principles contained in this Privacy Policy. Unauthorized
          access to Personal Data or the improper use of Personal Data may
          constitute offences under local law and under the regulations in force
          for Törnblom Software AB.
        </p>
        <h2 className="text-2xl">10. CONTACT</h2>
        <p>
          For any additional question concerning this Privacy Policy, or any
          request for rectification, completion, update, or deletion of Your
          Personal Data You may send an email to the following address:
          info@cargoplanner.se.
        </p>
        <h2 className="text-2xl">
          11. DATE OF ENTRY INTO FORCE OF THE PRIVACY POLICY AND CHANGES TO IT
        </h2>
        <p>
          This Privacy Policy may be updated according to Törnblom Software AB
          requirements and circumstances, or where required by applicable laws
          and regulations.
        </p>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicyPage
